import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Breadcrumb } from '~/components/Breadcrumbs';
import { subscriptionPropType } from '~/propTypes';

import pauseSubscriptionMutation from './_PauseSubscription.gql';
import resumeSubscriptionMutation from './_ResumeSubscription.gql';

const validationSchema = Yup.object().shape({
  pausedMonths: Yup.number().required('Antal månaders uppehåll måste anges.'),
});

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(3),
  },
}));

export default function SubscriptionPause({ subscriptionData }) {
  const classes = useStyles();

  const apollo = useApolloClient();

  const history = useHistory();

  const { subscription } = useParams();

  const { user, pauses } = subscriptionData.getSubscription;
  const pauseEndDate = pauses.subscription?.endDate;
  const isPaused = pauseEndDate ? new Date(pauseEndDate) > new Date() : false;

  const [pauseAction] = useMutation(pauseSubscriptionMutation, {
    onCompleted: async () => {
      await apollo.resetStore();
    },
  });

  const [resumeAction] = useMutation(resumeSubscriptionMutation, {
    onCompleted: async () => {
      await apollo.resetStore();
    },
  });

  const resumeHandler = useCallback(async () => {
    const response = await resumeAction({
      variables: {
        id: subscription,
        user: user.id,
      },
    });

    if (response) {
      await apollo.resetStore();
      history.push(`/subscriptions/${subscription}`);
    }
  }, [resumeAction, subscription, user.id, apollo, history]);

  return (
    <>
      <Breadcrumb name="Pausa prenumeration" />
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Pausa prenumeration
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Under uppehållet debiteras kunden inte för prenumerationen, och
            tillgången till e-tidningar och sajter tas bort. Förfallodatumet för
            prenumerationen justeras och skjuts fram med det antal månader som
            pausen gäller. Om kunden väljer att avbryta uppehållet, återupptas
            debiteringen samma dag.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Uppehåll sker i minst en månad och i max 3 månader.
          </Typography>

          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Kunden kan max pausa prenumerationen två gånger per år.
          </Typography>

          <Typography
            variant="body2"
            component="p"
            sx={theme => ({
              marginTop: theme.spacing(2),
            })}
          >
            Använd -Återuppta- knappen för att avbryta uppehållet.
          </Typography>
        </CardContent>
      </Card>

      <Formik
        initialValues={{
          pausedMonths: 1,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const response = await pauseAction({
            variables: {
              id: subscription,
              user: user.id,
              ...values,
            },
          });
          setSubmitting(false);

          if (response) {
            history.push(`/subscriptions/${subscription}`);
          }
        }}
        validateOnBlur={false}
      >
        {({ submitForm, isSubmitting }) => (
          <Form
            css={({ theme }) => ({
              '> * + *': {
                marginTop: theme.spacing(2),
              },
              '> *': {
                width: '100%',
              },
              '> .MuiFormControl-root': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <Field
              component={TextField}
              select
              name="pausedMonths"
              label="Pausade månader"
              variant="outlined"
            >
              {[1, 2, 3].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Field>
            {isSubmitting && <LinearProgress />}
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting || isPaused}
                onClick={submitForm}
              >
                Pausa
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isPaused}
                onClick={resumeHandler}
              >
                Återuppta
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

SubscriptionPause.propTypes = {
  subscriptionData: PropTypes.shape({
    getSubscription: subscriptionPropType,
  }).isRequired,
};
